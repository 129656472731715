import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticalsNavigationSectionComponent } from './verticals-navigation-section.component';
import { MatButtonModule } from '@angular/material/button';
import { SvgIconModule } from 'src/app/shared/icons/svg-icon/svg-icon.module';



@NgModule({
  declarations: [VerticalsNavigationSectionComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    SvgIconModule
  ],
  exports:[VerticalsNavigationSectionComponent]
})
export class VerticalsNavigationSectionModule { }
